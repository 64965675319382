<template>
  <template v-if="!item.hidden">
    <template v-if="item.sideBarType === 2">
      <el-popover placement="right" :width="252" trigger="click" popper-class="float-menu">
        <template #reference>
          <el-menu-item class="submenu-new-style" :index="item.name">
            <item :meta="item.meta" :class="{ 'hide-icon': item.hideIcon }" />
            <template #title>
              <span class="submenu-title">{{ item.meta.title }}</span>
            </template>
          </el-menu-item>
        </template>
        <FloatMenu :data="item" />
      </el-popover>
    </template>
    <template v-else>
      <div v-if="showSidebarItem(item.children, item)">
        <template v-if="item.isAsyncRoute && item.children && item.children.length === 0">
          <el-menu-item :index="item.name" :class="{ 'submenu-title-noDropdown': !isNest }" @click="tipNoAuth">
            <item :meta="onlyOneChild.meta || item.meta" />
            <template #title>
              <span>{{ item.meta.title }}</span>
            </template>
          </el-menu-item>
        </template>
        <template v-else>
          <Link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
            <el-menu-item
              :index="resolvePath(onlyOneChild.path)"
              :class="{ 'submenu-title-noDropdown': !isNest, 'submenu-new-style': isNest }"
              @click="reloadPage(item)"
            >
              <item :meta="onlyOneChild.meta || item.meta" />
              <template #title>
                <span class="submenu-title">{{ onlyOneChild.meta?.title }}</span>
              </template>
            </el-menu-item>
          </Link>
        </template>
      </div>
      <el-sub-menu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
        <template v-if="item.meta" #title>
          <item :meta="item.meta" />
          <span>{{ item.meta.title }}</span>
        </template>
        <SidebarItem
          v-for="child in item.children"
          :key="child.path"
          :is-nest="true"
          :item="child"
          :base-path="resolvePath(child.path)"
        />
      </el-sub-menu>
    </template>
  </template>
</template>

<script>
/*初始化参数比如引入组件，proxy,state等*/
import Link from './Link.vue'
import Item from './Item.vue'
import { isExternal } from '@/utils/validate'
import { resolve } from 'pathBrowser'
import { defineComponent, ref, toRefs } from 'vue'
import FloatMenu from './FloatMenu.vue'
import { message } from '@/utils/message'
export default defineComponent({
  name: 'SideBarItem',
  components: {
    Link,
    Item,
    FloatMenu
  },
  props: {
    //每一个router Item
    item: {
      type: Object,
      required: true
    },
    //用于判断是不是子Item,设置响应的样式
    isNest: {
      type: Boolean,
      default: false
    },
    //基础路径，用于拼接
    basePath: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { item } = toRefs(props)
    //基础功能特殊处理
    // if (item.value.isBasic) {
    //   if (item.value.children.length === 0) {
    //     item.value.hidden = true
    //   }
    // }
    let onlyOneChild = ref(null)
    let showSidebarItem = (children = [], parent) => {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          onlyOneChild.value = item
          return true
        }
      })

      if (parent.isFrame === '0') {
        let framePath = location.origin + parent.path
        if (import.meta.env.VITE_APP_IS_LOCAL_SERVE) {
          framePath =
            location.protocol + '//' + import.meta.env.VITE_APP_EXTERNAL_LINK_ENV + 'cloud.chinapji.com' + parent.path
        }

        onlyOneChild.value = { ...parent, path: framePath }
        return true
      }
      //指明是目录特殊处理
      if (parent.isDir) return false
      if (showingChildren.length === 1 && !parent?.alwaysShow) {
        return true
      }
      if (showingChildren.length === 0) {
        onlyOneChild.value = { ...parent, path: '', noChildren: true }
        return true
      }
      return false
    }
    let resolvePath = (routePath) => {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(props.basePath)) {
        return props.basePath
      }
      return resolve(props.basePath, routePath)
    }
    const tipNoAuth = () => {
      message('当前账号没有开通该项功能', { type: 'error' })
    }
    const reloadPage = (item) => {
      if(item.isFrame == '0') {
        setTimeout(() => {
          window.location.reload()
        })
      }
    }
    return {
      onlyOneChild,
      resolvePath,
      showSidebarItem,
      tipNoAuth,
      reloadPage
    }
  }
})
</script>

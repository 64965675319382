<!--
 * @Author: fangjun
 * @Date: 2023-01-30 08:37:33
 * @LastEditors: fangjun
 * @Description: 
 * @LastEditTime: 2023-07-11 08:40:10
 * @FilePath: \src\layout\components\Sidebar\Item.vue
-->
<template>
  <ElSvgItem :elSvgName="meta.elSvgIcon" v-if="meta.elSvgIcon" />
  <svg-icon :icon-class="meta?.icon" className="nav-icon" :class="meta?.icon" v-else />
</template>
<script>
import { computed, defineComponent } from 'vue'
import ElSvgItem from './ElSvgItem.vue'
export default defineComponent({
  components: {
    ElSvgItem
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    meta: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    elIcon: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const meta = computed(() => {
      return props.meta
    })
    return {
      meta
    }
  }
})
</script>

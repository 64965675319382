/*
 * @Author: fangjun
 * @Date: 2022-08-01 14:28:18
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2022-08-16 10:33:27
 * @FilePath: \src\api\userMgt\index.js
 */
import request from '@/utils/axiosReq'

/**
 * @description:获取用户列表
 * @param {*} query
 * @return {*}
 */
export function apiGetUserList(data) {
  return request({
    url: '/system/platform/userList',
    method: 'post',
    data
  })
}

/**
 * @description:添加用户
 * @param {*} query
 * @return {*}
 */
export function apiAddUser(data) {
  return request({
    url: '/system/platform/adduser',
    method: 'post',
    data
  })
}

/**
 * @description:重置密码
 * @param {*} query
 * @return {*}
 */
export function apiResetUserPassword(data) {
  return request({
    url: '/system/platform/resetPwd',
    method: 'post',
    data
  })
}

/**
 * @description:删除用户
 * @param {*} query
 * @return {*}
 */
export function apiDelUser(data) {
  return request({
    url: '/system/platform/deleteuser',
    method: 'post',
    data
  })
}

/**
 * @description:更新用户
 * @param {*} query
 * @return {*}
 */
export function apiUpdateUser(data) {
  return request({
    url: '/system/platform/updateuser',
    method: 'post',
    data
  })
}

/**
 * @description:获取角色下拉
 * @param {*} query
 * @return {*}
 */
export function apiGetRoleListSelect(data) {
  return request({
    url: '/system/subscribe/optionselect',
    method: 'get',
    data
  })
}

import { constantRoutes } from '@/router'
import { defineStore } from 'pinia'
import { getMenus } from '@/api/login'
import Layout from '@/layout/index.vue'
import { shallowRef } from 'vue'
import { platformsType } from '@/config/constant'

let globComponents = null

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter((route) => {
    if (type && route.children) {
      route.children = filterChildren(route.children)
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        route.component = shallowRef(Layout)
      } else {
        if (!route.hasPermission) {
          route.component = 'errorPage/403'
        }

        route.component = shallowRef(loadView(route.component))
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type)
    } else {
      delete route['children']
      delete route['redirect']
    }
    if (!route.hasPermission) {
      // route.redirect = '/403'
      route.hidden = true
    }
    return true
  })
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = []
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView') {
        el.children.forEach((c) => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}

export const loadView = (view) => {
  let path = `/src/views/${view}.vue`
  // 路由懒加载
  //* @vite-ignore */
  // return () => import(path)
  // return ()=>defineAsyncComponent(() => import(path))
  return globComponents[path]
}

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some((role) => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach((route) => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

export const usePermissionStore = defineStore({
  id: 'permission',
  state: () => ({
    routes: [],
    addRoutes: [],
    platforms: []
  }),
  actions: {
    SET_ROUTES(routes) {
      this.addRoutes = routes
      let allRoutes = constantRoutes.concat(routes)
      let sortBeforeRoutes = [],
        noSortRoutes = []
      allRoutes.map((item) => {
        if (item.sort) {
          sortBeforeRoutes.push(item)
        } else {
          noSortRoutes.push(item)
        }
      })
      let sortAfterRoutes = sortBeforeRoutes.sort((a, b) => {
        return a.sort - b.sort
      })
      this.routes = [...sortAfterRoutes, ...noSortRoutes]
    },
    generateRoutes() {
      globComponents = import.meta.glob(['/src/views/*/*.vue', '/src/views/*/*/*vue'])
      return new Promise((resolve) => {
        getMenus()
          .then((res) => {
            // getRobot({ customAreaId: customAreaId.value }).then((result) => {
            //   state.rawRobots = result.data
            // })

            let remoteRoutesRaw = res.data
            let curPlatformMenu = remoteRoutesRaw.filter((platformMenu) => platformMenu.platformType === '-2')[0]
            let otherPlatformMenu = remoteRoutesRaw.filter((platformMenu) => platformMenu.platformType !== '-2')
            let platforms = []

            otherPlatformMenu.forEach((menu) => {
              platforms.push(menu.platformType)
            })
            this.setPlatforms(platforms)
            let remoteRoutes = otherPlatformMenu.map((item) => {
              let route = {
                name: item.platformName,
                path: '/',
                redirect: 'noRedirect',
                component: shallowRef(Layout),
                children: calcOtherPlatformMenu(item.routerVoList),
                sideBarType: 2, // 用于区分自定义侧边栏和旧的侧边栏
                hideIcon: true,
                meta: {
                  title: item.platformName,
                  icon: 'documentation'
                }
              }
              return route
            })
            remoteRoutes = remoteRoutes.filter((route) => route.children.length > 0)
            if (!curPlatformMenu) {
              resolve()
            }

            let currentRouteList = filterAsyncRouter(curPlatformMenu.routerVoList)

            let path403 = {
              path: '/403',
              name: 'errorPage',
              component: shallowRef(Layout),
              hidden: true,
              children: [
                {
                  path: '/403',
                  name: '403',
                  component: () => import('@/views/errorPage/403.vue'),
                  meta: {
                    title: '403',
                    icon: '403'
                  }
                }
              ]
            }
            let dynamicIndexPath = null
            let currentRoutes = currentRouteList.map((route, index) => {
              let tempRoute = {
                name: route.name,
                path: route.path,
                redirect: route.redirect,
                component: route.component,
                sort: index + 1,
                sideBarType: 1,
                hidden: route.hidden,
                children: route.children,
                meta: route.meta,
                isDir: route.name && route.isFrame !== '0' ? true : false,
                isFrame: route.isFrame
              }
              if (!dynamicIndexPath && route.children && route.children.length > 0 && route.hasPermission) {
                let target = route.children.find((children) => children.hasPermission && !children.hidden) ?? null
                if (target) {
                  let redirectPath = route.path + '/' + target.path
                  redirectPath = redirectPath.replace(/\/{2,}/g, '/')
                  dynamicIndexPath = {
                    path: '/dynamicIndex',
                    redirect: redirectPath,
                    hidden: true
                  }
                }
              }
              return tempRoute
            })
            if (dynamicIndexPath) {
              path403.children[0].redirect = '/dynamicIndex'
            } else {
              dynamicIndexPath = { path: '/dynamicIndex', redirect: '/403', hidden: true }
            }
            currentRoutes.push(path403)
            currentRoutes.push(dynamicIndexPath)
            currentRoutes.push({ path: '/:pathMatch(.*)*', redirect: '/dynamicIndex', hidden: true })

            for (let i = 0; i < currentRoutes.length; i++) {
              let item = currentRoutes[i]
              if (item.meta && item.meta.title === '基础功能') {
                item.children = remoteRoutes
                break
              }
            }
            this.SET_ROUTES(currentRoutes)
            resolve()
          })
          .catch((err) => {})
      })
    },
    setPlatforms(platforms) {
      this.platforms = platforms
    }
  }
})
/**
 * @description: 将hasPermission为false的移除
 * @param {*} routeList
 * @return {*}
 */
const calcOtherPlatformMenu = (routeList) => {
  let res = routeList.filter((route) => {
    // route.hasPermission = Math.random() > 0.5 ? true : false
    if (route.hasPermission) {
      if (route.children && route.children.length > 0) {
        route.children = calcOtherPlatformMenu(route.children)
        if (route.children.length === 0) {
          return false
        }
      }
      return true
    } else {
      return false
    }
  })
  return res
}

<template>
  <div class="float-side-bar" @mouseenter="mEnter" @mouseleave="mLeave">
    <div class="float-side-bar-bg"></div>
    <div class="float-side-bar-main">
      <el-scrollbar>
        <div v-for="(item, idx) in floatSideBar" :key="idx">
          <div class="float-menu-item">
            <div class="route-title" :class="{ 'route-self': item.onlySelf }">
              <Link :to="resolvePath(item)" v-if="item.onlySelf" class="route-link">{{ item.meta?.title }}</Link>
              <span v-else>{{ item.meta?.title }}</span>
            </div>
            <ul class="route-list" v-if="!item.onlySelf">
              <li class="route-item route-link" v-for="(child, childIdx) in item.children" :key="childIdx">
                <Link :to="resolvePath(item, child)">{{ child.meta?.title }}</Link>
              </li>
            </ul>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent } from 'vue'
import Link from './Link.vue'
import { isExternal } from '@/utils/validate'
import { resolve } from 'pathBrowser'
export default defineComponent({
  name: 'FloatSideBar',
  components: {
    Link
  },
  props: ['data'],
  setup(props) {
    const floatSideBar = computed(() => {
      let res = []
      if (props.data && props.data.children) {
        res = props.data.children.map((item) => {
          item.isDir = item.name ? true : false
          item.onlySelf = true
          if (item.isDir || (item.children && item.children.length > 1)) {
            item.onlySelf = false
          }
          if (item.children && item.children.length < 2) {
            if (!item.meta) {
              item.meta = {
                title: item.children[0].meta.title
              }
            }
          }
          return item
        })
      }
      return res
    })
    let resolvePath = (parent, child) => {
      if (child && isExternal(child.path)) {
        return child.path
      }
      let pagePath
      if (child) {
        pagePath = location.origin + resolve(parent.path, child.path)
      } else {
        if (parent.children) {
          pagePath = location.origin + resolve(parent.path, parent.children[0].path)
        } else {
          pagePath = location.origin + parent.path
        }
      }
      return pagePath
    }
    return {
      floatSideBar,
      resolvePath
    }
  }
})
</script>
<style lang="scss" scoped>
.float-side-bar {
  position: relative;
  width: 100%;
  height: 100%;
  .float-side-bar-bg {
    width: 100%;
    height: 100%;
    background: rgba(22, 29, 38, 0.5);
    backdrop-filter: blur(10px);
  }
  .float-side-bar-main {
    padding-top: 80px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .float-menu-item {
      margin-bottom: 10px;
      .route-title {
        padding: 12px 30px;
        font-size: 16px;
        font-weight: 400;
        border-bottom: 1px solid rgba(255, 255, 255, 0.14);
      }
      .route-self {
        border-bottom: none;
      }
      .route-list {
        padding: 14px 30px;
        .route-item {
          padding-left: 1em;
          margin-bottom: 16px;
          cursor: pointer;
        }
      }
      .route-link {
        &:hover {
          color: #0281ff;
        }
      }
    }
  }
}
</style>

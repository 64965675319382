/*
 * @Author: fangjun
 * @Date: 2022-08-08 09:04:32
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2022-08-16 16:30:38
 * @FilePath: \src\api\userCenter\index.js
 */
import request from '@/utils/axiosReq'

/**
 * @description: 获取个人信息
 * @param {*} data
 * @return {*}
 */
export function apiGetUserInfo(data) {
  return request({
    url: '/system/platform/getInfo',
    method: 'get',
    data
  })
}

/**
 * @description: 修改密码
 * @param {*} data
 * @return {*}
 */
export function apiModifyPassword(data) {
  return request({
    url: '/system/platform/changePwd',
    method: 'post',
    data
  })
}

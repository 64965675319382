<!--
 * @Author: fangjun
 * @Date: 2023-01-30 08:37:33
 * @LastEditors: fangjun
 * @Description: 
 * @LastEditTime: 2023-08-29 15:03:54
 * @FilePath: \src\layout\components\Sidebar\index.vue
-->
<template>
  <div>
    <logo :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item v-for="route in permissionRoutes" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
// import variablesScss from '@/style/variables.scss'
import { useRoute } from 'vue-router'
import { usePermissionStore } from '@/store/modules/permission'
import { useAppStore } from '@/store/modules/app'

export default defineComponent({
  name: 'SideBar',
  components: { SidebarItem, Logo },
  setup() {
    const route = useRoute()
    const permissionStore = usePermissionStore()
    const appStore = useAppStore()
    const activeMenu = computed(() => {
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    })
    const variables = computed(() => {
      return {}
    })
    const sidebar = computed(() => {
      return appStore.sidebar
    })
    const isCollapse = computed(() => {
      return !appStore.sidebar.opened
    })
    const permissionRoutes = computed(() => {
      return permissionStore.routes
    })
    return {
      activeMenu,
      variables,
      isCollapse,
      sidebar,
      permissionRoutes
    }
  }
})
</script>
<style lang="scss" scoped>
.el-scrollbar {
  :deep(.el-scrollbar__bar.is-vertical) {
    display: none !important;
  }
}
</style>

<!--
 * @Author: fangjun
 * @Date: 2023-03-08 10:31:13
 * @LastEditors: fangjun
 * @Description: 
 * @LastEditTime: 2023-04-27 14:56:39
 * @FilePath: \src\layout\components\AppMain.vue
-->
<template>
  <section class="app-main">
    <!-- <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view :key="key" />
      </keep-alive>
    </transition> -->
    <!-- <el-scrollbar> -->
    <router-view v-slot="{ Component }">
      <transition mode="out-in" name="fade-transform">
        <keep-alive :include="cachedViews">
          <component :is="Component" :key="key" />
        </keep-alive>
      </transition>
    </router-view>
    <!-- </el-scrollbar> -->
  </section>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'AppMain',
  setup() {
    const route = useRoute()
    const key = computed(() => {
      return route.path
    })
    const cachedViews = computed(() => {
      return []
    })
    return {
      key,
      cachedViews
    }
  }
})
</script>

<style lang="scss" scoped>
.app-main {
  height: 100%;
  padding-top: 60px;
  width: 100%;
  position: relative;
  overflow: hidden;
  .app-page {
    padding: 20px;
    height: 100%;
    background-color: #F7F8FA;
  }
  // :deep(.el-scrollbar__view) {
  //   height: 100%;
  // }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>

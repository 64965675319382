import axios from 'axios'
import { ElLoading } from 'element-plus'
import { message } from '@/utils/message'
import { getToken, removeToken } from '@/utils/auth'
import { useUserStore } from '@/store/modules/user'
let reqConfig
let loadingE
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: import.meta.env.VITE_APP_BASE_API,
  // 超时
  timeout: 20000
})
// request
service.interceptors.request.use(
  (req) => {
    req.cancelToken = new axios.CancelToken((cancel) => {
      //__axiosPromiseArr收集请求地址
      window.__axiosPromiseArr = window.__axiosPromiseArr ?? []
      window.__axiosPromiseArr.push({
        url: req.url,
        cancel
      })
    })
    // token setting
    req.headers['Authorization'] = import.meta.env.VITE_APP_TOKEN_BEAR + ' ' + getToken()
    /* download file*/
    if (req.isDownLoadFile) {
      req.responseType = 'blob'
    }
    /* upload file*/
    if (req.isUploadFile) {
      req.headers['Content-Type'] = 'multipart/form-data'
    }
    if (req.bfLoading) {
      loadingE = ElLoading.service({
        lock: true,
        text: req.bfLoadingText,
        target: '.app-main'
      })
    }
    /*
     *params会拼接到url上,such as  "a=1&b=2"
     * */
    if (req.isParams) {
      req.params = req.data
      req.data = {}
    }
    //save req for res to using
    reqConfig = req
    return req
  },
  (err) => {
    Promise.reject(err)
  }
)
/**
 * @description: 取消上一次请求 业务接口除了查询 不要使用
 * @return {*}
 */
export function cancelLastRequest() {
  window.__axiosPromiseArr?.pop()?.cancel('取消请求')
}
//response
service.interceptors.response.use(
  (res) => {
    if (reqConfig.afHLoading && loadingE) {
      loadingE.close()
    }
    // direct return, when download file
    if (reqConfig.isDownLoadFile) {
      return res
    }
    let { msg, code } = res.data
    // 未设置状态码则默认成功状态
    code = code || 200
    const successCode = '0,200,20000'
    if (successCode.includes(code)) {
      //业务成功处理
      return res.data
    } else {
      //是否需要提示错误信息 isAlertErrorMsg:true 提示
      if (reqConfig.isAlertErrorMsg) {
        if (code === 500) {
          message(msg, { type: 'error', duration: 2 * 1000 })
        } else if (code === 401) {
          message(msg, { type: 'warning', duration: 2 * 1000 })
          const userStore = useUserStore()
          userStore.logout().then(() => {
            location.href = '/login'
          })
        } else if (code == 806) {
          // message(msg, { type: 'success', duration: 2 * 1000 })
        } else {
          message(msg, { type: 'warning', duration: 2 * 1000 })
        }
      }
      //返回错误信息
      //如果未catch 走unhandledrejection进行收集
      //注：如果没有return 则，会放回到请求方法中.then ,返回的res为 undefined
      return Promise.reject(res.data)
    }
  },
  (err) => {
    /*http错误处理，处理跨域，404，401，500*/
    if (loadingE) loadingE.close()
    // let { message } = err
    let errMsg = err.message
    if (errMsg == 'Network Error') {
      errMsg = '服务端通信异常'
    } else if (errMsg.includes('timeout')) {
      errMsg = '服务端响应超时'
    } else if (errMsg.includes('Request failed with status code')) {
      errMsg = '系统接口' + errMsg.substr(errMsg.length - 3) + '异常'
    }
    message(errMsg, { type: 'error', duration: 2 * 1000 })
    //如果是跨域
    //Network Error,cross origin
    let errObj = {
      msg: err.toString(),
      reqUrl: reqConfig.baseURL + reqConfig.url,
      params: reqConfig.isParams ? reqConfig.params : reqConfig.data
    }
    return Promise.reject(JSON.stringify(errObj))
  }
)

export function axiosReq({
  url,
  data,
  method,
  isParams,
  bfLoading,
  bfLoadingText,
  afHLoading,
  isUploadFile,
  isDownLoadFile,
  baseURL,
  timeout,
  isAlertErrorMsg,
  onUploadProgress,
  onDownloadProgress
}) {
  return service({
    url: url,
    method: method ?? 'get',
    data: data ?? {},
    isParams: isParams ?? method === 'get' ? true : false,
    bfLoading: bfLoading ?? false,
    bfLoadingText: bfLoadingText ?? '数据载入中...',
    afHLoading: afHLoading ?? true,
    isUploadFile: isUploadFile ?? false,
    isDownLoadFile: isDownLoadFile ?? false,
    isAlertErrorMsg: isAlertErrorMsg ?? true,
    baseURL: baseURL ?? import.meta.env.VITE_APP_BASE_URL, // 设置基本基础url
    timeout: timeout,
    onUploadProgress,
    onDownloadProgress
  })
}
export default axiosReq

import { defineStore } from "pinia";
import Cookies from 'js-cookie'
export const useAppStore = defineStore({
  id: "app",
  state: () => ({
    sidebar: {
      opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
      withoutAnimation: false
    },
    device: 'desktop',
    size: Cookies.get('size') || 'medium'
  }),
  getters: {

  },
  actions: {
    TOGGLE_SIDEBAR() {
      this.sidebar.opened = !this.sidebar.opened
      this.sidebar.withoutAnimation = false
      if (this.sidebar.opened) {
        Cookies.set('sidebarStatus', 1)
      } else {
        Cookies.set('sidebarStatus', 0)
      }
    },
    CLOSE_SIDEBAR(withoutAnimation) {
      Cookies.set('sidebarStatus', 0)
      this.sidebar.opened = false
      this.sidebar.withoutAnimation = withoutAnimation
    },
    OPEN_SIDEBAR(withoutAnimation) {
      Cookies.set('sidebarStatus', 1)
      this.sidebar.opened = true
      this.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE(device) {
      this.device = device
    },
    SET_SIZE(size) {
      this.size = size
      Cookies.set('size', size)
    }
  }
});

/*
 * @Author: fangjun
 * @Date: 2022-08-01 09:14:21
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-07-05 10:24:00
 * @FilePath: \src\utils\validate.js
 */
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  return typeof str === 'string' || str instanceof String
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * @description: 姓名校验
 * @param {*}
 * @return {*}
 */
export const checkUserName = (rule, value, callback) => {
  let reg = /^[\s\u4e00-\u9fa5A-Za-z0-9]{0,}$/
  if (!reg.test(value)) {
    callback(new Error('姓名中含有非法字符'))
  } else if (value.length < 1 || value.length > 25) {
    callback(new Error('姓名必须为1~25个字符'))
  } else {
    callback()
  }
}
/**
 * @description: 手机格式校验
 * @param {*}
 * @return {*}
 */
export const checkPhone = (rule, value, callback) => {
  let reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
  if (!reg.test(value)) {
    callback(new Error('手机号格式不正确'))
  } else {
    callback()
  }
}

/**
 * @description: 密码
 * @param {*}
 * @return {*}
 */
export const checkPassword = (rule, value, callback) => {
  let reg = /^(?=.*[a-zA-Z])(?=.*\d).+$/
  let reg2 = /[\u4e00-\u9fa5]+/
  let reg3 = /\s/
  if (!reg.test(value)) {
    callback(new Error('密码必须含有英文和数字'))
  } else if (reg2.test(value)) {
    callback(new Error('密码不能有中文'))
  } else if (reg3.test(value)) {
    callback(new Error('密码不能有空格'))
  } else if (value.length < 6 || value.length > 20) {
    callback('密码必须为6~20位')
  } else {
    callback()
  }
}

export const checkAmrName = (rule, value, callback) => {
  let reg0 = /^[\u4E00-\u9FA5A-Za-z0-9_-]{1,}$/
  let reg1 = /^[_-]{1,}$/
  if (!reg0.test(value)) {
    callback(new Error('设备名称含有非法字符'))
  } else if (reg1.test(value)) {
    callback(new Error('设备名称不能全部为下划线，中划线或中下划线混合'))
  } else callback()
}

/**
 * @description: 机器人SN码
 * @param {*}
 * @return {*}
 */
export const checkSn = (rule, value, callback) => {
  let reg = /^(?![0-9]+$)(?![A-Z]+$)[0-9A-Z]{17}$/
  if (!reg.test(value)) {
    callback(new Error('机器人SN码只能是17位大写英文字母和数字组合'))
  } else {
    callback()
  }
}
/**
 * @description: 地图名称
 * @param {*}
 * @return {*}
 */
export const checkMapName = (rule, value, callback) => {
  let reg0 = /^[\u4E00-\u9FA5A-Za-z0-9_-]{1,}$/
  let reg1 = /^[_-]{1,}$/
  if (!reg0.test(value)) {
    callback(new Error('名称含有非法字符'))
  } else if (reg1.test(value)) {
    callback(new Error('名称不能全部为下划线，中划线或中下划线混合'))
  } else callback()
}
/**
 * @description: 建筑物名称
 * @param {*}
 * @return {*}
 */
export const checkBuildName = (rule, value, callback) => {
  let reg0 = /^[\u4E00-\u9FA5A-Za-z0-9_-]{0,}$/
  let reg1 = /^[_-]{0,}$/
  if (value.length === 0) callback()
  else if (!reg0.test(value)) {
    callback(new Error('名称含有非法字符'))
  } else if (reg1.test(value)) {
    callback(new Error('名称不能全部为下划线，中划线或中下划线混合'))
  } else callback()
}

/**
 * @description: 建筑物楼层
 * @param {*}
 * @return {*}
 */
export const checkBuildFloor = (rule, value, callback) => {
  if (value == 0) {
    callback(new Error('楼层不能为0'))
  } else {
    callback()
  }
}

/**
 * @description: 全空格
 * @param {*}
 * @return {*}
 */
 export const checkAllBlank = (rule, value, callback) => {
  value = value ?? ''
  if (value.trim().length === 0) {
    callback(new Error('输入不能全部为空格'))
  } else callback()
}
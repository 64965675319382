<template>
  <div class="pji-time-select">
    <div class="time-option-item" @click="select(time)" v-for="(time, idx) in timeArr" :key="idx" :class="time.value === modelValue ? 'is-active' : ''">
      <span>{{ time.label }}</span>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
  name: "TimeSelect",
  props: ['times', 'modelValue'],
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const state = reactive({});
    const timeArr = computed(() => {
      return props.times;
    });
    const select = (time) => {
        emit('update:modelValue', time.value)
    }
    return {
      ...toRefs(state),
      timeArr,
      select
    };
  },
});
</script>
<style lang="scss" scoped>
.pji-time-select {
  height: 40px;
  background-color: #151d26;
  color: #fff;
  display: flex;
  padding: 6px;
  .time-option-item {
    font-size: 14px;
    cursor: pointer;
    padding: 4px 8px;
    display: flex;
    height: 28px;
    
    border-radius: 6px;
    margin-right: 10px;
    .item-text {
      height: 20px;
      line-height: 20px;
      display: inline-block;
      font-weight: 400;
    }
    &:last-child {
        margin-right: 0;
    }
  }
  .time-option-item.is-active {
    background-color: #0281ff;
    box-shadow: 0px 2px 8px 0px rgba(2,129,255,0.5);
  }
}
</style>
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * @description: GET 请求 映射参数
 * @param {*} url
 * @param {*} params
 * @return {*}
 */
export function parameterMapping(url, params) {
  url = url + '?'
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    var part = encodeURIComponent(propName) + '='
    if (value !== null && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          let params = propName + '[' + key + ']'
          var subPart = encodeURIComponent(params) + '='
          url += subPart + encodeURIComponent(value[key]) + '&'
        }
      } else {
        url += part + encodeURIComponent(value) + '&'
      }
    }
  }
  url = url.slice(0, -1)
  params = {}
  return url
}
//颜色生成器
export function generateColors(count) {
  var colors = []
  for (var i = 0; i < count; i++) {
    var hue = (i * 360) / count // 色相变化
    var saturation = 65 + '%' // 较高的饱和度
    var lightness = 50 + '%' // 中等的亮度
    colors.push('hsl(' + hue + ', ' + saturation + ', ' + lightness + ')')
  }
  return colors
}

// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/')
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatSeconds(value) {
  let secondTime = parseInt(value) // 秒
  let minuteTime = '00' // 分
  let hourTime = '00' // 小时
  hourTime = Math.floor(value / 3600)
  minuteTime = Math.floor(value / 60)
  secondTime = Math.floor(value % 60)
  let result = hourTime + ':' + minuteTime + ':' + secondTime
  return result
}

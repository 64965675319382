<!--
 * @Author: fangjun
 * @Date: 2022-08-03 08:46:03
 * @LastEditors: fangjun
 * @Description: 
 * @LastEditTime: 2023-04-27 15:25:34
 * @FilePath: \src\views\userCenter\dialog\modifyPassword.vue
-->
<template>
  <el-dialog
    v-model="dialog.visible"
    title="修改密码"
    width="450px"
    :close-on-click-modal="false"
    :append-to-body="true"
    @open="open"
    @closed="closed"
  >
    <div class="text-center">
      <el-form ref="form" v-if="showForm" :model="formParams" label-width="100px" label-position="right" :rules="rules">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            type="password"
            v-model.trim="formParams.oldPassword"
            placeholder="请输入旧密码"
            maxlength="20"
            show-word-limit
            show-password
            style="width: 300px"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="新密码" prop="password">
          <el-input
            type="password"
            v-model.trim="formParams.password"
            placeholder="请输入新密码"
            maxlength="20"
            show-word-limit
            show-password
            style="width: 300px"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="确认新密码" prop="confirmPassword">
          <el-input
            type="password"
            placeholder="请确认新密码"
            maxlength="20"
            v-model.trim="formParams.confirmPassword"
            show-word-limit
            show-password
            clearable
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="save" :loading="saving">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { getCurrentInstance, reactive, toRefs } from 'vue'
import { apiModifyPassword } from '@/api/userCenter'
import { checkPassword } from '@/utils/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useUserStore } from '@/store/modules/user'
import { useRouter } from 'vue-router'

export default {
  name: 'ModifyPassword',
  props: ['dialog'],
  setup(props) {
    const state = reactive({
      showForm: false,
      saving: false,
      formParams: {
        userId: '',
        oldPassword: '',
        password: '',
        confirmPassword: ''
      },
      rules: {
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'change' }],
        password: [
          { required: true, message: '请输入新密码', trigger: 'change' },
          { validator: checkPassword, trigger: 'change' },
          { validator: checkOldSame, trigger: 'change' }
        ],
        confirmPassword: [
          { required: true, message: '请确认新密码', trigger: 'change' },
          { validator: checkPassword, trigger: 'change' },
          { validator: checkSame, trigger: 'change' }
        ]
      }
    })

    const userStore = useUserStore()
    const router = useRouter()
    let { dialog } = toRefs(props)
    let { proxy } = getCurrentInstance()

    function checkSame(rule, value, cb) {
      if (state.formParams.password !== state.formParams.confirmPassword) {
        return cb(new Error('两次密码输入不一致'))
      } else {
        return cb()
      }
    }

    function checkOldSame(rule, value, cb) {
      if (state.formParams.password === state.formParams.oldPassword) {
        return cb(new Error('新密码不能与旧密码相同'))
      } else {
        return cb()
      }
    }

    function modifyPassword() {
      state.saving = true
      let { userId, oldPassword, password } = state.formParams
      let params = { userId, oldPassword, password }
      apiModifyPassword(params)
        .then((result) => {
          if (result.code === 200) {
            ElMessage({
              message: '密码修改成功!',
              type: 'success'
            })
            dialog.value.visible = false
            ElMessageBox.alert('密码修改成功，需要重新登录', '提示', {
              // if you want to disable its autofocus
              // autofocus: false,
              confirmButtonText: '确定',
              callback: () => {
                userStore.logout().then(() => {
                  router.push({ name: '登录' })
                })
              }
            })
          }
        })
        .catch((err) => {
          state.saving = false
        })
    }
    function loadUserInfo() {
      let { id } = userStore.user
      state.formParams['userId'] = id
    }
    function save() {
      proxy.$refs['form'].validate((valid) => {
        if (valid) {
          modifyPassword()
        }
      })
    }
    function cancel() {
      dialog.value.visible = false
    }
    function open() {
      state.formParams = {
        ...state.formParams,
        oldPassword: '',
        password: '',
        confirmPassword: ''
      }
      state.showForm = true
    }
    function closed() {
      state.saving = false
      state.showForm = false
    }
    loadUserInfo()
    return {
      ...toRefs(state),
      save,
      cancel,
      open,
      closed
    }
  }
}
</script>

/*
 * @Author: fangjun
 * @Date: 2023-11-09 08:47:14
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-11-14 10:00:39
 * @FilePath: \src\api\home\index.js
 */
import request from '@/utils/axiosReq'

/**
 * @description: 获取设备信息
 * @param {*} data
 * @return {*}
 */
export function getChildCustomArea(data) {
  return request({
    url: '/robion/overview/childCustomArea',
    method: 'get',
    data
  })
}
/**
 * @description: 获取设备信息
 * @param {*} data
 * @return {*}
 */
export function getRobot(data) {
  return request({
    url: '/robion/overview/robot',
    method: 'post',
    data
  })
}

/**
 * @description: 获取工作时长
 * @param {*} data
 * @return {*}
 */
export function getWorkTime(data) {
  return request({
    url: '/robion/overview/workTime',
    method: 'post',
    data
  })
}

/**
 * @description: 获取任务概览
 * @param {*} data
 * @return {*}
 */
export function getTask(data) {
  return request({
    url: '/robion/overview/task',
    method: 'post',
    data
  })
}

/**
 * @description: 服务机器人统计 饼图
 * @param {*} data
 * @return {*}
 */
export function apiGetStatisticsForService(data) {
  return request({
    timeout: 5 * 60 * 1000,
    url: '/serviceApp/statistics/statisticsByTpe',
    method: 'post',
    data
  })
}

/**
 * @description: 在线率
 * @param {*} data
 * @return {*}
 */
export function apiGetOnlineRate(data) {
  return request({
    url: '/robion/overview/historyOnline',
    method: 'post',
    data
  })
}

/**
 * @description: 行驶距离
 * @param {*} data
 * @return {*}
 */
export function apiGetTravelDistance(data) {
  return request({
    url: '/robion/overview/historyMileageQuery',
    method: 'post',
    data
  })
}
/**
 * @description: 任务数
 * @param {*} data
 * @return {*}
 */
export function apiGetTaskNumber(data) {
  return request({
    url: '/robion/overview/historyTaskQuery',
    method: 'post',
    data
  })
}

/**
 * @description: 平均行驶距离
 * @param {*} data
 * @return {*}
 */
 export function apiGetAverageTravelDistance(data) {
  return request({
    url: '/robion/overview/historyAvgMileageQuery',
    method: 'post',
    data
  })
}

/**
 * @description: 平均任务数
 * @param {*} data
 * @return {*}
 */
 export function apiGetAverageTaskNumber(data) {
  return request({
    url: '/robion/overview/historyAvgTaskQuery',
    method: 'post',
    data
  })
}

/**
 * @description: 昨日行驶距离
 * @param {*} data
 * @return {*}
 */
 export function apiGetYesterdayTravelDistance(data) {
  return request({
    url: '/robion/overview/topMileageQuery',
    method: 'post',
    data
  })
}

/**
 * @description: 昨日任务数
 * @param {*} data
 * @return {*}
 */
 export function apiGetYesterdayTaskNumber(data) {
  return request({
    url: '/robion/overview/topTaskQuery',
    method: 'post',
    data
  })
}
/*
 * @Author: qiankun
 * @LastEditors: qiankun
 */
import { defineStore } from 'pinia';

export const useNotifyStore = defineStore('notify', {
    state: () => ({
        isShow: false,
    }),
    actions: {
        toggle() {
            this.isShow = !this.isShow
        },
        setShow(isShow) {
            this.isShow = isShow
        }
    }
});
/*
 * @Author: qiankun
 * @LastEditors: qiankun
 */
import Cookies from 'js-cookie'
let EnvPrefix = ''  // 生产:空字符串 预发:pre 测试:test 开发:dev （以上环境均是部署打包环境，特别注意dev不是指本地开发环境而是部署到开发服务器上的环境）
if(document.domain.indexOf('pre') > -1) {
  EnvPrefix = 'Pre-'
} else if(document.domain.indexOf('test') > -1) {
  EnvPrefix = 'Test-'
} else if(document.domain.indexOf('dev') > -1) {
  EnvPrefix = 'Dev-'
}
const TokenKey = `${EnvPrefix}App-Token`

const ExpiresInKey = `${EnvPrefix}App-Expires-In`

let UserNameKey = `${EnvPrefix}App-Username`

let PasswordKey = `${EnvPrefix}App-Password`

let RememberMeKey = `${EnvPrefix}App-RememberMe`

const isLocalhost = document.domain === 'localhost' 
let cookieDomain = ''
const IpReg= /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
if (isLocalhost) {
  cookieDomain = ''
} else if (IpReg.test(document.domain)) {
  cookieDomain = ''
} else {
  cookieDomain = document.domain.split('.').slice(-2).join('.')
}
export const domain = cookieDomain

export function clearToken() {
  removeToken()
  removeExpiresIn()
}

export function clearUserInfo() {
  removeUserName()
  removePassword()
  removeRememberMe()
}

export function clearUser() {
  clearToken()
  clearUserInfo()
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, {domain})
}

export function removeToken() {
  return Cookies.remove(TokenKey, {domain})
}

export function getExpiresIn() {
  return Cookies.get(ExpiresInKey)
}

export function setExpiresIn(time) {
  return Cookies.set(ExpiresInKey, time, {domain})
}

export function removeExpiresIn() {
  return Cookies.remove(ExpiresInKey, {domain})
}

export function getUserName() {
  return Cookies.get(UserNameKey)
}

export function setUserName(username) {
  return Cookies.set(UserNameKey, username, { expires: 30, domain })
}

export function removeUserName() {
  return Cookies.remove(UserNameKey, {domain})
}


export function getPassword() {
  return Cookies.get(PasswordKey)
}

export function setPassword(password) {
  return Cookies.set(PasswordKey, password, { expires: 30, domain })
}

export function removePassword() {
  return Cookies.remove(PasswordKey, {domain})
}

export function getRememberMe() {
  return Cookies.get(RememberMeKey)
}

export function setRememberMe(rememberMe) {
  return Cookies.set(RememberMeKey, rememberMe, { expires: 30, domain })
}

export function removeRememberMe() {
  return Cookies.remove(RememberMeKey, {domain})
}



/*
 * @Author: qiankun
 * @LastEditors: fangjun
 */
import request from '@/utils/axiosReq'
// 获取验证码
export function getCodeImg() {
  return request({
    url: '/code',
    method: 'get'
  })
}

// 登录方法
export function login(loginForm) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: loginForm
  })
}

// 登出方法
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'delete'
  })
}

// 获取用户详细信息
export function getUserInfo() {
  return request({
    url: '/system/platform/getInfo',
    method: 'get'
  })
}

// 获取客户区域信息
export function getCustomInfo() {
  return request({
    url: '/robion/customArea/detail',
    method: 'get'
  })
}

export function getAmrPermissions() {
  return request({
    url: '/system/platform/getAmrMenu',
    method: 'get'
  })
}

// 获取菜单
export function getMenus() {
  return request({
    url: '/system/platformMenu/getRouters',
    method: 'get'
  })
}
<template>
  <div :class="classObj" class="app-wrapper" v-resize>
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    />
    <side-bar class="sidebar-container"/>
    <div class="main-container">
      <nav-bar :device="device" />
      <app-main />
      <GlobalMessage />
    </div>
    <MessageDrawer />
  </div>
</template>
<script>
import { computed, defineComponent, onMounted } from "vue";
import { useAppStore } from "@/store/modules/app";
import AppMain from "./components/AppMain";
import SideBar from "./components/Sidebar";
import NavBar from "./components/NavBar";
import { emitter } from "@/utils/mitt";
import { breakWidth } from '@/config/constant'
import MessageDrawer from "./components/MessageDrawer/index.vue"
import GlobalMessage from './components/GlobalMessage/index.vue'
export default defineComponent({
  name: "LayoutIndex",
  components: {
    AppMain,
    SideBar,
    NavBar,
    MessageDrawer,
    GlobalMessage
  },
  setup() {
    const appStore = useAppStore();
    const classObj = computed(() => {
      return {
        hideSidebar: !appStore.sidebar.opened,
        openSidebar: appStore.sidebar.opened,
        withoutAnimation: appStore.sidebar.withoutAnimation,
        mobile: appStore.device === "mobile",
      };
    });
    
    const device = computed(() => {
      return appStore.device;
    });
    const sidebar = computed(() => {
      return appStore.sidebar;
    });
    const handleClickOutside = () => {
      appStore.CLOSE_SIDEBAR(false);
    };
    const isMobileHandle = () => {
      const rect = document.body.getBoundingClientRect()
      return rect.width - 1 < breakWidth
    }
    const watchResize = () => {
      emitter.on("resize", ({ detail }) => {
        const isMobile = isMobileHandle()
        appStore.TOGGLE_DEVICE(isMobile ? 'mobile' : 'desktop')
        if (isMobile) {
          appStore.CLOSE_SIDEBAR(true)
        } else {
          appStore.OPEN_SIDEBAR(false)
        }
      });
    };

    onMounted(() => {
      watchResize();
    });

    return {
      classObj,
      device,
      sidebar,
      handleClickOutside,
      watchResize,
      isMobileHandle,
    };
  },
});
</script>
<style lang="scss" scoped>
.app-wrapper {
  height: 100%;
  background-color: #F7F8FA;
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }
}
</style>
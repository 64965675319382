<template>
  <div class="message-drawer-wrapper" :class="{ 'is-hide': !isShow }">
    <el-drawer
      v-model="isShow"
      :show-close="false"
      style="position: absolute; top: 0px"
      class="message-drawer"
      size="400px"
      @open="open"
      @closed="closed"
    >
      <div class="drawer-title">
        <span class="title-border"></span>
        <span class="title-text">警报记录</span>
      </div>
      <div class="drawer-main">
        <div class="filter-box">
          <!-- <TimeSelect :times="times" v-model="time" class="drawer-time-select" /> -->
          <el-button
            v-for="(item, idx) in times"
            :key="idx"
            @click="time = item.value"
            :type="time == item.value ? 'primary' : ''"
          >
            {{ item.label }}
          </el-button>
        </div>
        <div class="drawer-list-wrapper" v-loading="loading">
          <el-scrollbar v-if="waringList.length > 0">
            <div class="warning-list" v-infinite-scroll="loadMore" infinite-scroll-distance="1">
              <div class="warning-card" v-for="(warning, idx) in waringList" :key="idx" @click="viewNotify(warning)">
                <div class="icon-box">
                  <SvgIcon icon-class="message-error" class="error-icon"></SvgIcon>
                </div>
                <div class="info-box">
                  <div class="warning-name">{{ warnTypeMap[warning.warnType] }}</div>
                  <div class="warning-amr">
                    <span>机器人：</span>
                    <span>{{ warning.name }}</span>
                  </div>
                  <div class="warning-level">
                    <span>严重等级：</span>
                    <span>{{ levelMap[warning.level] }}</span>
                  </div>
                  <div class="warning-time">
                    <span>发生时间：</span>
                    <span>{{ dealTime(warning.warningTime) }}</span>
                  </div>
                </div>
                <div class="btn-box">
                  <div class="go-more">
                    <span class="go-more-btn" @click.stop="goMore">更多>></span>
                  </div>
                  <el-button
                    type="primary"
                    @click.stop="unbindConfirm(warning)"
                    v-hasPermi="['globalConfig:alarm:relieve']"
                  >
                    解除
                  </el-button>
                </div>
              </div>
            </div>
          </el-scrollbar>
          <Empty v-if="!loading && waringList.length === 0" />
        </div>
      </div>
    </el-drawer>
    <el-dialog
      :title="`警报详情${currentSelected.warnType ? '(' + warnTypeMap[currentSelected.warnType] + ')' : ''}`"
      v-model="dialogVisible"
      width="500px"
      center
      :show-close="false"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <div class="detail-box" v-if="currentSelected.id">
        <div class="img-box" v-if="currentSelected.photoUrlList && currentSelected.photoUrlList.length > 0">
          <el-carousel indicator-position="none" height="150px">
            <el-carousel-item v-for="(item, idx) in currentSelected.photoUrlList" :key="idx">
              <el-image :src="item"></el-image>
            </el-carousel-item>
          </el-carousel>
          <!-- <div v-else class="img-one-wrapper">
            <img
              :src="currentSelected.photoUrlList[0]"
              class="record-pic"
              v-if="currentSelected.photoUrlList && currentSelected.photoUrlList.length === 1"
            />
            <img src="~@/assets/images/record_no_pic.png" class="record-no-pic" v-else />
          </div> -->
        </div>
        <div class="info-box">
          <div class="info-item">机器人：{{ currentSelected.name }}</div>
          <div class="info-item" v-if="currentSelected.mapId">地图名称：{{ currentSelected.mapName }}</div>
          <div class="info-item">严重等级：{{ levelMap[currentSelected.level] }}</div>
          <div class="info-item">状态：{{ statusMap[currentSelected.status] }}</div>
          <div class="info-item">发生时间：{{ currentSelected.createTime }}</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
          <el-button type="primary" @click="unbindRecord" :loading="confirmBtnLoading">解除</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, toRefs, watch } from 'vue'
import { useNotifyStore } from '@/store/modules/notify'
import TimeSelect from '@/components/TimeSelect'
import { getAlarmRecordList, relieveAlarm } from '@/api/xunjian'
import { headers } from '@/config/constant'
import { timeFormat } from '@/utils/other'
import { ElMessageBox } from 'element-plus'
import moment from 'moment'
import { message } from '@/utils/message'
import { emitter } from '@/utils/mitt'
import Empty from '@/components/Empty'
export default defineComponent({
  name: 'MessageDrawer',
  components: {
    TimeSelect,
    Empty
  },
  setup() {
    const notifyStore = useNotifyStore()
    const state = reactive({
      times: [
        {
          label: '全部',
          value: '0'
        },
        {
          label: '今天',
          value: '1'
        },
        {
          label: '最近1周',
          value: '2'
        },
        {
          label: '最近1个月',
          value: '3'
        }
      ],
      time: '0',
      loading: false,
      waringList: [],
      queryParams: {},
      total: 0,
      warnTypeMap: {
        1: '人员闯入',
        2: '高温警报',
        3: '车辆违停',
        4: '人脸不符',
        5: '未戴口罩',
        6: '障碍物阻塞',
        7: '体温异常',
        8: '灯光警报',
        10: '人员聚集',
        11: '穿越围栏',
        12: '区域入侵',
        13: '禁行线',
        14: '物品遗留',
        15: '快速移动',
        16: '停车检测',
        17: '物品搬运',
        18: '人员徘徊',
        19: '非机动车违停',
        20: '人脸识别',
        21: '灭火器缺位',
        22: '火情、烟雾',
        23: '打架斗殴',
        24: '人员摔倒',
        25: '人员抽烟',
        26: '未穿戴安全着装',
        27: '人员睡岗',
        28: '人员离岗',
        50: '门开关检测',
        51: '人员非法闯入（人脸识别）',
        52: '车辆非法闯入',
        53: '灭火器箱缺位',
        54: '人员非法闯入（行人检测）'
      },
      statusMap: {
        0: '未解除',
        1: '已解除'
      },
      levelMap: {
        1: '轻微',
        2: '一般',
        3: '严重'
      },
      confirmBtnLoading: false,
      dialogVisible: false,
      currentSelected: {},
      getListFlag: false
    })
    watch(
      () => state.time,
      (v) => {
        dealTimeGetData(v)
      }
    )
    const dealTime = (time) => {
      return timeFormat(time, 'YYYY年mm月dd日HH:MM:SS')
    }
    const initParams = (params) => {
      if (params) {
        state.queryParams = {
          ...state.queryParams,
          ...params
        }
      } else {
        state.queryParams = {
          amrId: '',
          status: '0',
          level: '',
          warnType: '',
          warningStartTime: '',
          warmingEndTime: '',
          pageSize: 10,
          pageNum: 1,
          orderByColumn: '',
          isAsc: ''
        }
      }
    }
    const isShow = computed(() => {
      return notifyStore.isShow
    })
    const loadMore = () => {
      if (state.waringList.length < state.total) {
        state.queryParams.pageNum++
        getList()
      }
    }
    const getList = (type) => {
      if (type === 'init') {
        state.waringList = []
        state.queryParams.pageNum = 1
      }
      state.loading = true
      getAlarmRecordList(state.queryParams)
        .then((res) => {
          state.getListFlag = true
          if (res.rows && res.rows.length > 0) {
            state.total = res.total
            res.rows.map((item) => {
              state.waringList.push(item)
            })
          }
        })
        .finally(() => {
          state.loading = false
        })
    }
    const goMore = () => {
      window.open(headers['xunjian'] + '/alarmRecord/list?status=0', '_blank')
    }
    const dealTimeGetData = (v) => {
      initParams()
      let endTime = moment().format('YYYY-MM-DD')
      if (v === '0') {
        state.queryParams.warningStartTime = ''
        state.queryParams.warmingEndTime = ''
      } else if (v === '1') {
        state.queryParams.warningStartTime = `${endTime} 00:00:00`
        state.queryParams.warmingEndTime = `${endTime} 23:59:59`
      } else if (v === '2') {
        let time = moment()
          .day(moment().day() - 6)
          .format('YYYY-MM-DD')
        state.queryParams.warningStartTime = `${time} 00:00:00`
        state.queryParams.warmingEndTime = `${endTime} 23:59:59`
      } else if (v === '3') {
        let time = moment().subtract(1, 'months').format('YYYY-MM-DD')
        state.queryParams.warningStartTime = `${time} 00:00:00`
        state.queryParams.warmingEndTime = `${endTime} 23:59:59`
      }
      getList('init')
    }
    const unbind = (record) => {
      let { id } = record
      return relieveAlarm({ ids: [id], isAll: 0 }).then((res) => {
        message('解除成功', { type: 'success' })
        getList('init')
      })
    }
    const unbindConfirm = (item) => {
      ElMessageBox.confirm('确定要解绑该条警报？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            unbind(item).finally(() => {
              done()
            })
          } else {
            done()
          }
        }
      })
        .then(() => {})
        .catch(() => {})
    }
    const viewNotify = (item) => {
      state.currentSelected = item
      state.dialogVisible = true
    }
    const closed = () => {
      notifyStore.setShow(false)
    }
    const open = () => {
      getList('init')
    }
    const unbindRecord = () => {
      state.confirmBtnLoading = true
      unbind(state.currentSelected)
        .then(() => {
          state.dialogVisible = false
        })
        .finally(() => {
          state.confirmBtnLoading = false
        })
    }
    const watchRedPoint = () => {
      emitter.on('redPointChange', () => {
        if (state.time === '0') {
          dealTimeGetData('0')
        } else {
          state.time = '0'
        }
      })
    }
    onMounted(() => {
      watchRedPoint()
      initParams()
    })
    return {
      ...toRefs(state),
      isShow,
      loadMore,
      goMore,
      dealTime,
      unbindConfirm,
      viewNotify,
      unbindRecord,
      closed,
      open
    }
  }
})
</script>

<style lang="scss" scoped>
.message-drawer-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 60px;
  .drawer-title {
    height: 65px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebeef5;
    padding: 0 1vw;
    .title-text {
      color: #131414;
      // padding-left: 24px;
      font-weight: 500;
    }
  }
  .drawer-main {
    height: 100%;
    padding-top: 65px;
    .filter-box {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      .drawer-time-select {
        background-color: #1f2935;
      }
    }
    .drawer-list-wrapper {
      height: calc(100% - 100px);
      .warning-list {
        padding: 0 20px 100px;
        .warning-card {
          width: 360px;
          height: 133px;
          background: #fbfdff;
          border-radius: 8px;
          display: flex;
          padding: 18px 10px 16px 10px;
          margin-bottom: 20px;
          cursor: pointer;
          .icon-box {
            .error-icon {
              font-size: 18px;
            }
          }
          .info-box {
            margin: 0 10px;
            width: 240px;
            .warning-name {
              height: 22px;
              font-size: 16px;
              font-weight: 600;
              color: #4e5969;
              line-height: 22px;
              margin-bottom: 5px;
            }
            .warning-amr,
            .warning-level,
            .warning-time {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #4e5969;
              line-height: 20px;
              margin-bottom: 5px;
              opacity: 0.76;
            }
          }
          .btn-box {
            .go-more {
              margin-bottom: 36px;
            }
            .go-more-btn {
              height: 22px;
              font-size: 16px;
              font-weight: 600;
              color: #0281ff;
              line-height: 22px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .detail-box {
    display: flex;
    justify-content: center;
    // .img-box,
    // .info-box {
    //   flex: 1;
    // }
    .img-box {
      width: 220px;
      padding-right: 5px;
    }
    .info-box {
      width: 220px;
      padding-left: 5px;
    }
    .img-one-wrapper {
      display: flex;
      justify-content: center;

      img.record-pic {
        max-width: 100%;
        max-height: 145px;
        width: auto;
        height: auto;
      }
    }
    .record-type {
      margin-top: 10px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
    }

    .info-item {
      margin-bottom: 10px;
    }

    .record-no-pic {
      width: 156px;
      height: 120px;
      opacity: 0.7;
    }
  }
}
.message-drawer-wrapper.is-hide {
  z-index: -1;
}
</style>

<style lang="scss">
.message-drawer-wrapper {
  .el-overlay {
    top: 60px;
    .el-drawer__header {
      display: none;
    }
    .el-drawer__body {
      padding: 0;
      color: #4e5969;
      position: relative;
    }
  }
}
</style>
